import React, { useState, useEffect } from 'react';
import { Loader } from 'lucide-react';
import { TransferProgressBar } from '../../TransferProgressBar';
import { calculateFees } from '../../../../../utils/feeCalculations';
import { apiConfig, getFetchConfig } from '../../../../../config/api';

const TransactionComplete = ({ 
  listing,
  userType, // 'buyer' or 'seller'
  onComplete,
  onNavigate
}) => {
  const [rating, setRating] = useState(0);
  const [showRatingUI, setShowRatingUI] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const fees = calculateFees(listing.price);

  useEffect(() => {
    const hasBeenRated = listing.ratings?.[userType]?.score;
    setShowRatingUI(!hasBeenRated);
  }, [listing, userType]);

  const handleSubmitRating = async () => {
    try {
      setIsProcessing(true);
      setError(null);
      const token = localStorage.getItem('token');

      // Submit rating - note that the type is correct here:
      // If buyer is rating, they're rating the seller
      // If seller is rating, they're rating the buyer
      const ratingResponse = await fetch(
        `${apiConfig.listings.update(listing._id)}/rate`,
        {
          method: 'POST',
          ...getFetchConfig(token),
          body: JSON.stringify({
            rating,
            type: userType === 'buyer' ? 'seller' : 'buyer'
          })
        }
      );

      if (!ratingResponse.ok) {
        const errorData = await ratingResponse.json();
        throw new Error(errorData.message || 'Failed to submit rating');
      }

      console.log(`${userType} rating submitted successfully`);

      // If seller, attempt to capture payment
      if (userType === 'seller') {
        try {
          console.log('Attempting payment capture for intent:', listing.paymentIntentId);
          const captureResponse = await fetch(
            apiConfig.payments.capturePayment(listing.paymentIntentId),
            {
              method: 'POST',
              ...getFetchConfig(token)
            }
          );

          let responseData;
          try {
            responseData = await captureResponse.json();
          } catch (e) {
            responseData = await captureResponse.text();
          }

          console.log('Payment capture response:', {
            status: captureResponse.status,
            data: responseData
          });

          if (!captureResponse.ok && 
              !responseData?.status?.includes('succeeded')) {
            console.warn('Payment capture failed:', responseData);
          }
        } catch (captureError) {
          console.error('Payment capture error:', captureError);
          // Don't throw here - we want to continue even if capture fails
        }
      }

      if (onComplete) {
        onComplete();
      }

      setShowRatingUI(false);

    } catch (error) {
      console.error('Error completing transaction:', error);
      setError(error.message || 'Failed to complete transaction');
    } finally {
      setIsProcessing(false);
    }
  };
  
  const renderTransactionDetails = () => {
    if (userType === 'buyer') {
      return (
        <div className="w-full space-y-3 border border-gray-200 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <span className="text-base">Purchase Price</span>
            <span className="text-base font-semibold">${listing.price.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Transaction Fee</span>
            <span className="text-base">+${fees.transactionFee.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Processing Fee</span>
            <span className="text-base">+${fees.processingFee.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center pt-2 border-t border-gray-200">
            <span className="text-lg font-semibold">Total Paid</span>
            <span className="text-lg font-semibold text-[#FF4C03]">
              ${fees.total.toFixed(2)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full space-y-3 border border-gray-200 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <span className="text-base">Sale Price</span>
            <span className="text-base font-semibold">${listing.price.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Platform Fee</span>
            <span className="text-base">-$0.00</span>
          </div>
          <div className="flex justify-between items-center pt-2 border-t border-gray-200">
            <span className="text-lg font-semibold">Total Received</span>
            <span className="text-lg font-semibold text-green-600">
              ${listing.price.toFixed(2)}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full max-w-screen-lg">
      <h2 className="text-2xl font-semibold text-center">
        Transaction Complete
      </h2>
      
      <p className="text-sm text-center text-gray-500 mt-2 mb-6">
        ID: {listing._id}
      </p>

      <div className="w-full mb-8">
        <TransferProgressBar status="complete" />
      </div>

      {error && (
        <div className="p-4 bg-red-50 text-red-600 rounded-lg mb-4 text-center">
          {error}
        </div>
      )}

      {showRatingUI ? (
        <>
          <p className="text-center text-lg mt-1 mb-6">
            {userType === 'buyer' 
              ? 'Thank you! Please leave a rating for the seller.'
              : 'Thank you! Please leave a rating for the buyer to release your funds.'}
          </p>

          <div className="text-center mb-8 space-x-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => setRating(star)}
                className={`text-5xl ${
                  star <= rating ? 'text-[#FF4C03]' : 'text-gray-300'
                }`}
              >
                ★
              </button>
            ))}
          </div>

          <button
            onClick={handleSubmitRating}
            disabled={!rating || isProcessing}
            className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full disabled:opacity-50 flex items-center justify-center"
          >
            {isProcessing ? (
              <>
                <Loader className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              'Confirm'
            )}
          </button>
        </>
      ) : (
        renderTransactionDetails()
      )}
    </div>
  );
};

export default TransactionComplete;