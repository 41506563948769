import React, { useState } from 'react';
import { Loader } from 'lucide-react';
import { TransferProgressBar } from '../../TransferProgressBar';
import { apiConfig, getFetchConfig } from '../../../../../config/api';

const ConfirmationFlow = ({ listingId, eventId, onNavigate }) => {
  const [currentStep, setCurrentStep] = useState('confirm');
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleConfirmReceipt = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');
      const response = await fetch(
        apiConfig.listings.updateStatus(listingId),
        {
          method: 'PATCH',
          ...getFetchConfig(token),
          body: JSON.stringify({
            status: 'confirmed'
          })
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to confirm receipt');
      }

      setCurrentStep('rate');
      
    } catch (error) {
      console.error('Error confirming receipt:', error);
      setError(error.message || 'Failed to confirm receipt');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitRating = async () => {
    if (!rating) return;

    try {
      setIsLoading(true);
      setError(null);

      const token = localStorage.getItem('token');
      const response = await fetch(
        `${apiConfig.listings.update(listingId)}/rate`,
        {
          method: 'POST',
          ...getFetchConfig(token),
          body: JSON.stringify({
            rating,
            type: 'seller'
          })
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit rating');
      }

      onNavigate(`/event/${eventId}/listing-success`, {
        state: { 
          listingId,
          eventId,
          status: 'completed',
          showRatingThanks: true
        },
        replace: true
      });

    } catch (error) {
      console.error('Error submitting rating:', error);
      setError(error.message || 'Failed to submit rating');
    } finally {
      setIsLoading(false);
    }
  };

  if (currentStep === 'confirm') {
    return (
      <div className="flex flex-col items-center pt-6">
        <h2 className="text-2xl font-semibold text-center mb-2">
          Confirmation Required
        </h2>
        
        <p className="text-center text-sm mb-8">
          The seller has completed the transfer, which has been verified by SectionU.
        </p>

        <TransferProgressBar status="ticket_sent" />

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg mt-4 mb-4">
            {error}
          </div>
        )}

        <p className="text-center text-sm mt-4 mb-2">
          Failure to confirm receipt of transfer below may result in account review and/or suspension.
          If you have not received the ticket please message the seller below.
        </p>

        <div className="w-full mt-8 space-y-4">
          <button
            onClick={handleConfirmReceipt}
            disabled={isLoading}
            className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full disabled:opacity-50 flex items-center justify-center"
          >
            {isLoading ? (
              <>
                <Loader className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              'Confirm Receipt'
            )}
          </button>

          <button
            className="w-full py-4 border-2 border-[#FF4C03] text-[#FF4C03] rounded-full"
          >
            Message Seller
          </button>
        </div>
      </div>
    );
  }

  if (currentStep === 'rate') {
    return (
      <div className="flex flex-col items-center pt-6">
        <h2 className="text-2xl font-semibold text-center mb-4">
          Rate the seller
        </h2>

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg mb-4">
            {error}
          </div>
        )}

        <div className="flex space-x-2 my-6">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => setRating(star)}
              className={`text-3xl transition-colors ${
                star <= rating ? 'text-[#FF4C03]' : 'text-gray-300'
              } hover:opacity-80`}
            >
              ★
            </button>
          ))}
        </div>

        <button
          onClick={handleSubmitRating}
          disabled={!rating || isLoading}
          className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full disabled:opacity-50 flex items-center justify-center mt-4"
        >
          {isLoading ? (
            <>
              <Loader className="mr-2 h-4 w-4 animate-spin" />
              Processing...
            </>
          ) : (
            'Submit Rating'
          )}
        </button>
      </div>
    );
  }

  return null;
};

export default ConfirmationFlow;